import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Ticker from 'react-ticker'

import lilu from "../images/logos/lilu-logo.svg";
import tanzerei from "../images/logos/logo-tanzerei_luzern.svg";
import { GatsbyImage } from 'gatsby-plugin-image';


export default function LogoCloud() {
    return (
        <div className="bg-gray-100 py-24 lg:py-48" >

            <div class="relative flex flex-col max-w-screen snap-start text-black items-center justify-center">
                <div class="">
                    <div class="flex text-left">
                        <h1 class="py-4 text-5xl mb-8 font-heading">Auf unsere Zusammenarbeit 🍻</h1>
                    </div>
                </div>
            </div>

            {/* <Ticker> */}
                {/* {({ index }) => ( */}

                    <div className="mt-6 flex flex-nowrap gap-24 overflow-scroll mx-6">
                        <StaticQuery
                            query={logoQuery}
                            render={({ ante, droste, titlis, luzern, unesco, willisau, yoga }) => {

                                const items = [
                                    {
                                        logo: ante,
                                        label: "Ante Konzerte",
                                        bgColor: ""
                                    },
                                    {
                                        logo: droste,
                                        label: "Droste Verlag",
                                        bgColor: ""
                                    },
                                    {
                                        logo: titlis,
                                        label: "Titlis Bergbahnen",
                                        bgColor: ""
                                    },
                                    {
                                        logo: luzern,
                                        label: "Luzern Tourismus",
                                        bgColor: ""
                                    },
                                    {
                                        logo: unesco,
                                        label: "Unesco Biosphäre Entlebuch",
                                        bgColor: ""
                                    },
                                    {
                                        logo: willisau,
                                        label: "Willisau Tourismus",
                                        bgColor: ""
                                    },
                                    {
                                        logo: yoga,
                                        label: "yoga meets weggis",
                                        bgColor: "bg-[#BBD5BB]"
                                    },
                                    {
                                        logo: lilu,
                                        label: "Lichtfestival Luzern",
                                        bgColor: "bg-pink-400 px-4"
                                    },
                                    {
                                        logo: tanzerei,
                                        label: "Tanzerei",
                                        bgColor: "bg-white px-4"
                                    }
                                ]

                                return (
                                    items.map(({ logo, label, bgColor }) => {
                                        const cls = "h-auto w-48 object-contain"
                                        return (
                                            <div className={`flex flex-1 justify-center ${bgColor}`}>
                                                {typeof logo === 'object' && 'childImageSharp' in logo &&
                                                    <GatsbyImage
                                                        imgClassName={cls}
                                                        className={cls}
                                                        objectFit="contain"
                                                        image={logo.childImageSharp.gatsbyImageData}
                                                        alt={label}
                                                    />
                                                }
                                                {typeof logo !== 'object' &&
                                                    <img
                                                        src={logo}
                                                        label={label}
                                                        className={`flex max-w-none ${cls}`}
                                                    />
                                                }
                                            </div>
                                        )
                                    })
                                )
                            }}
                        />
                    </div>
                {/* )} */}
            {/* </Ticker> */}
        </div>
    )
}

export const logoQuery = graphql`
fragment blogImage on File {
    childImageSharp {
        gatsbyImageData(
            placeholder: BLURRED
        )
    }
  }
  query {
    ante:   file(relativePath: { eq: "logos/ante-logo.png" }) {
      ...blogImage
    }

    droste: file(relativePath: { eq: "logos/logo-droste-verlag.png" }) {
        ...blogImage
    }

    titlis: file(relativePath: { eq: "logos/logo-titlis-engelberg-bahnen.png" }) {
        ...blogImage
    }

    luzern: file(relativePath: { eq: "logos/luzern-tourismus.jpg" }) {
        ...blogImage
    }

    unesco: file(relativePath: { eq: "logos/unesco-biosphaere-entebuch.jpg" }) {
        ...blogImage
    }

    willisau: file(relativePath: { eq: "logos/willisau-tourismus.jpg" }) {
        ...blogImage
    }

    yoga: file(relativePath: { eq: "logos/yoga-meets-weggis.webp" }) {
        ...blogImage
    }
  }
`