import '../App.css';
import React from "react";
import { useRef, useEffect } from "react";
import { graphql, StaticQuery } from 'gatsby';

import BlogPreview from '../components/BlogPreview';

import Seo from '../components/seo';
import ProjektePreview from '../components/ProjektePreview';
import ImageHero from '../components/ImageHero';
import Section2Col, { Section2Left } from '../components/Section2Col';

import uebermich1 from '../images/ueber-mich-1.png';
import uebermich2 from '../images/ueber-mich-2.png';
import uebermich3 from '../images/ueber-mich-3.png';
import uebermich4 from '../images/ueber-mich-4.png';
import TwoColCTA from '../components/TwoColCTA';
import LogoCloud from '../components/LogoCloud';



// https://play.tailwindcss.com/lQk1tAEWet

const Section = Section2Col


const IndexPage = () => {

  const ref = useRef(null);

  return (
    <div>
      <Seo title='Über mich - Laila schreibt' />
      <div className="wrapper__section">
        {/* snap-y snap-mandatory h-screen overflow-y-scroll */}
        <StaticQuery
          query={aboutQuery}
          render={data => {
            return (
              <>
                <Section
                  id="section-1"
                  title="Hallo!"
                  imgStyle="h-full w-full object-cover"
                  gatsbyImageData={data.image2.childImageSharp.gatsbyImageData}
                >
                  <p className='prose font-light'>
                    Ich bin Laila, 26 Jahre jung und eine waschechte Luzernerin. Meine grosse Leidenschaft ist es, Geschichten zu erzählen. Mit meinem Notizbuch im Rucksack, meiner Fotokamera in den Pfoten und einem Lachen im Gesicht streife ich durch die Gassen der Luzerner Altstadt oder das kniehohe Gras der Alpwiesen. Dabei suche ich stets nach Menschen und Geschichten, die mich inspirieren. Welcome to my world.                   </p>
                </Section>
                <Section2Left
                  id="section-2"
                  title="4 Fakten über mich"
                  imgStyle="h-full w-full object-cover"
                  className="relative grid md:grid-cols-2 snap-start max-w-screen lg:min-h-[50vh]"
                  gatsbyImageData={data.image1.childImageSharp.gatsbyImageData}
                >
                  <ul className='grid grid-cols-2 gap-24 list-decimal list-inside styled'>
                    <li className='z-10 prose font-light'>
                      Ich schreibe gerne frech, frei von der Leber und mit einem Augenzwinkern. Und irgendwie auch für meine Eltern. Schliesslich testen sie alle Orte aus, welche ich in meinen Blogs nenne.
                    </li>
                    <li className='z-10 prose font-light'>
                      Ich bin eine Frohnatur mit dem Orientierungssinn einer Seegurke. Nein wirklich. Du musst mal mit mir wandern kommen. Oder dir diesen Text zu Gemüte führen
                    </li>
                    <li className='z-10 prose font-light'>
                      Wenn ich mir etwas in den Kopf gesetzt habe, dann sitze ich nächtelang über meinem Projekt und feile daran, bis es perfekt ist.
                    </li>
                    <li className='z-10 prose font-light'>
                      Ob im Tanzstudio, an der Bushaltestelle oder beim Gemüseregal im Supermarkt. Ich bin immer tanzend anzutreffen.
                    </li>
                  </ul>
                </Section2Left>
              </>
            )
          }}
        />

        <div className="relative flex flex-col py-24 max-w-screen snap-start bg-themeLightBlue text-white items-center justify-center">
          <div className=''>
            <div className='flex text-left'>
              <h1 className="py-4 text-5xl  mb-8 font-heading">Laila, sag mal...</h1>
            </div>
            <div className='flex flex-row'>
              <div className="flex flex-col gap-4 text-xl hyphens-auto leading-relaxed">
                <div className="imessage">
                  <p className="from-them">
                    Wo gibt es den besten Kaffee in Luzern?
                  </p>
                </div>
                <div className="imessage">
                  <p className="from-them">
                    Was ist eine gute Location für ein Date?
                  </p>
                </div>
                <div className="imessage">
                  <p className="from-them">
                    Welche Wanderung ist besonders hübsch?
                  </p>
                </div>
                <div className="imessage">
                  <p className="from-them">
                    Gehört Ananas auf eine Pizza?
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LogoCloud />

        <TwoColCTA
          title="Hier gibt's Content"
          text="Text, Fotografie, Film, Social Media oder Blogbeiträge – ich bin am Start"
          btnLabel="Let's do it"
        />


      </div>

    </div>
  )
}

export default IndexPage;

export const aboutQuery = graphql`
      fragment blogImage on File {
        childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
      )
    }
  }
      query {
        image1:   file(relativePath: {eq: "portrait-laila-schreibt.jpg" }) {
        ...blogImage
      }

      image2: file(relativePath: {eq: "laila-schreibt-about.jpg" }) {
        ...blogImage
      }
  }
      `
